import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
    Container, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Button, Box, TextField, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Alert,
    Breadcrumbs, Link, Checkbox, FormControlLabel
} from '@mui/material';
import { useSelector } from 'react-redux';
import axios from 'axios';

const GlossaryTerms = () => {
    const { glossaryId } = useParams();
    const token = useSelector((state) => state.auth.token);
    const [terms, setTerms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [newTerm, setNewTerm] = useState({ source: '', target: '', context: '', ignore: false });
    const [editingTerm, setEditingTerm] = useState(null);

    useEffect(() => {
        const fetchTerms = async () => {
            try {
                const response = await axios.get(`/api/glossary/${glossaryId}/terms`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setTerms(response.data);
            } catch (err) {
                setError('Failed to load glossary terms.');
            } finally {
                setLoading(false);
            }
        };
        fetchTerms();
    }, [glossaryId, token]);

    const handleAddTerm = async () => {
        if (!newTerm.source) {
            setError('Please provide a source term.');
            return;
        }

        // Automatically set ignore to true if target is empty
        if (!newTerm.target) {
            newTerm.ignore = true;
        }

        try {
            let updatedTerm;
            if (editingTerm) {
                const response = await axios.put(`/api/glossary/${glossaryId}/terms/${editingTerm._id}`, newTerm, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                updatedTerm = response.data;
                setTerms(terms.map((term) => (term._id === editingTerm._id ? updatedTerm : term)));
            } else {
                const response = await axios.post(`/api/glossary/${glossaryId}/terms`, newTerm, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                updatedTerm = response.data;
                setTerms([...terms, updatedTerm]);
            }

            setNewTerm({ source: '', target: '', context: '', ignore: false });
            setEditingTerm(null);
            setOpenDialog(false);
        } catch (err) {
            setError('Failed to add/edit term.');
        }
    };

    const handleEditClick = (term) => {
        setEditingTerm(term);
        setNewTerm(term);
        setOpenDialog(true);
    };

    const handleDeleteTerm = async (termId) => {
        try {
            await axios.delete(`/api/glossary/${glossaryId}/terms/${termId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setTerms((prevTerms) => prevTerms.filter((term) => term._id !== termId));
        } catch (err) {
            setError('Failed to delete term.');
        }
    };

    const handleIgnoreChange = (event) => {
        const isChecked = event.target.checked;
        setNewTerm((prevTerm) => ({
            ...prevTerm,
            ignore: isChecked,
            target: isChecked ? '' : prevTerm.target,  // Clear target if ignore is checked
        }));
    };

    if (loading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error}</Alert>;

    return (
        <Container maxWidth="md" sx={{ marginTop: '50px' }}>
            {/* Breadcrumbs */}
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '20px' }}>
                <Link component={RouterLink} to="/dashboard/language-settings" color="inherit">
                    Language Settings
                </Link>
                <Typography color="textPrimary">Glossary Terms</Typography>
            </Breadcrumbs>

            <Typography variant="h4" gutterBottom>Glossary Terms</Typography>

            {/* Add Term Form */}
            <Paper elevation={3} sx={{ padding: '20px', marginBottom: '20px' }}>
                <Typography variant="h6">Add a New Term</Typography>
                <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        label="Source Term"
                        value={newTerm.source}
                        onChange={(e) => setNewTerm({ ...newTerm, source: e.target.value })}
                        fullWidth
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={newTerm.ignore}
                                onChange={handleIgnoreChange}
                                color="primary"
                            />
                        }
                        label="Ignore Term"
                    />
                    <TextField
                        label="Target Term"
                        value={newTerm.target}
                        onChange={(e) => setNewTerm({ ...newTerm, target: e.target.value })}
                        fullWidth
                        disabled={newTerm.ignore}  // Disable if ignore is checked
                    />
                    <TextField
                        label="Context"
                        value={newTerm.context}
                        onChange={(e) => setNewTerm({ ...newTerm, context: e.target.value })}
                        fullWidth
                        placeholder="Optional information about the term's usage"
                    />
                    <Button variant="contained" color="primary" onClick={handleAddTerm}>
                        {editingTerm ? 'Save Changes' : 'Add Term'}
                    </Button>
                </Box>
            </Paper>

            <Paper elevation={3} sx={{ padding: '20px' }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Source Term</TableCell>
                                <TableCell>Target Term</TableCell>
                                <TableCell>Context</TableCell>
                                <TableCell>Ignore</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {terms.length > 0 ? (
                                terms.map((term) => (
                                    <TableRow key={term._id}>
                                        <TableCell>{term.source}</TableCell>
                                        <TableCell>{term.target}</TableCell>
                                        <TableCell>{term.context}</TableCell>
                                        <TableCell>{term.ignore ? 'Yes' : 'No'}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => handleEditClick(term)}
                                                sx={{ marginRight: 1 }}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                onClick={() => handleDeleteTerm(term._id)}
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        No terms available.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            {/* Add/Edit Dialog */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>{editingTerm ? 'Edit Term' : 'Add New Term'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Source Term"
                        type="text"
                        fullWidth
                        value={newTerm.source}
                        onChange={(e) => setNewTerm({ ...newTerm, source: e.target.value })}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={newTerm.ignore}
                                onChange={handleIgnoreChange}
                                color="primary"
                            />
                        }
                        label="Ignore Term"
                    />
                    <TextField
                        margin="dense"
                        label="Target Term"
                        type="text"
                        fullWidth
                        value={newTerm.target}
                        onChange={(e) => setNewTerm({ ...newTerm, target: e.target.value })}
                        disabled={newTerm.ignore}  // Disable if ignore is checked
                    />
                    <TextField
                        margin="dense"
                        label="Context"
                        type="text"
                        fullWidth
                        value={newTerm.context}
                        onChange={(e) => setNewTerm({ ...newTerm, context: e.target.value })}
                        placeholder="Optional usage information"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">Cancel</Button>
                    <Button onClick={handleAddTerm} color="primary">
                        {editingTerm ? 'Save Changes' : 'Add Term'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default GlossaryTerms;
