import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Container, Button, Typography, Paper, Box, TextField, MenuItem, Select, InputLabel, FormControl, Grid, LinearProgress } from '@mui/material';
import Flag from 'react-world-flags';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';  // Import useLocation

const NewTranslation = () => {
    const [file, setFile] = useState(null);
    const [fileNameOnServer, setFileNameOnServer] = useState('');  // Store the filename as saved on the server
    const [successMessage, setSuccessMessage] = useState('');  // For success messages
    const [error, setError] = useState('');  // For error messages
    const [projectName, setProjectName] = useState('');  // Store project name
    const [sourceLanguage, setSourceLanguage] = useState('');  // Store source language code
    const [targetLanguages, setTargetLanguages] = useState([]);  // Store multiple target language codes
    const [deadline, setDeadline] = useState('');  // Optional deadline field
    const [uploadProgress, setUploadProgress] = useState(0);  // Upload progress state
    const token = useSelector((state) => state.auth.token);  // Get JWT token from Redux
    const navigate = useNavigate();  // Hook for navigation
    const location = useLocation();  // Hook to get the current route

    // Language options with language codes
    const languageOptions = [
        { name: 'English', code: 'EN', countryCode: 'GB' },
        { name: 'Spanish', code: 'ES', countryCode: 'ES' },
        { name: 'French', code: 'FR', countryCode: 'FR' },
        { name: 'German', code: 'DE', countryCode: 'DE' },
        { name: 'Chinese', code: 'ZH', countryCode: 'CN' },
        { name: 'Japanese', code: 'JA', countryCode: 'JP' },
        { name: 'Italian', code: 'IT', countryCode: 'IT' },

    ];

    // Pre-upload the file when selected/dropped
    const preUploadFile = useCallback(async (selectedFile) => {
        if (!selectedFile) {
            setError('Please select or drop a file.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await axios.post('/api/new-translation/pre-upload', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,  // Include the authorization token
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(progress);  // Update upload progress
                }
            });

            // Save the full filename returned from the server response
            setFileNameOnServer(response.data.fileName);
            setSuccessMessage('File uploaded successfully.');
        } catch (error) {
            console.error('Error pre-uploading file:', error);
            setError('Failed to upload file.');
        }
    }, [token]);

    // Handle file drop
    const onDrop = useCallback((acceptedFiles) => {
        setFile(acceptedFiles[0]);
        setError('');  // Reset error message
        setSuccessMessage('');  // Reset success message
        setUploadProgress(0);  // Reset upload progress

        // Automatically start uploading the file as soon as it's dropped/selected
        preUploadFile(acceptedFiles[0]);
    }, [preUploadFile]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: '.doc, .docx, .idml',  // Add .doc and .docx support
        multiple: false,  // Accept only one file at a time
    });


    // Function to handle file deletion
    const deleteFile = useCallback(async () => {
        if (fileNameOnServer && location.pathname !== '/new-translation/delete-file') {  // Exclude this route
            try {
                await axios.delete(`/api/new-translation/delete-file/${encodeURIComponent(fileNameOnServer)}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,  // Include the authorization token
                    }
                });
                setFile(null);
                setFileNameOnServer('');
                setSuccessMessage('');
                setUploadProgress(0);
            } catch (error) {
                console.error('Error deleting file:', error);
                setError('Failed to delete the file.');
            }
        }
    }, [fileNameOnServer, token, location.pathname]);  // Include location in dependencies

    // Cleanup file on route change or page unload (refresh/close tab)
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (fileNameOnServer && location.pathname !== '/new-translation/delete-file') {
                deleteFile();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            if (fileNameOnServer && location.pathname !== '/new-translation/delete-file') {
                deleteFile();
            }
        };
    }, [deleteFile, fileNameOnServer, location.pathname]);

    // Handle project submission for multiple target languages
    const handleProjectCreation = async () => {
        if (!file || !projectName || !sourceLanguage || targetLanguages.length === 0) {
            setError('Please fill in all the fields and select a file.');
            return;
        }

        try {
            // Create a FormData object to send all data, including targetLanguages as JSON
            const formData = new FormData();
            formData.append('file', file);  // Append file
            formData.append('projectName', projectName);  // Append project name
            formData.append('sourceLanguage', sourceLanguage);  // Append source language code
            formData.append('targetLanguages', JSON.stringify(targetLanguages));  // Send the array of target languages as a JSON string
            formData.append('deadline', deadline);  // Append deadline (optional)

            await axios.post('/api/new-translation/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,  // Include the authorization token
                },
            });

            setSuccessMessage('Projects created successfully!');
            setFile(null);
            setFileNameOnServer('');
            setProjectName('');
            setSourceLanguage('');
            setTargetLanguages([]);  // Reset after creation
            setDeadline('');
            setUploadProgress(0);

        } catch (err) {
            console.error('Error uploading file:', err);
            setError('Error uploading or processing the file.');
        }
    };


    // Function to display file size in KB or MB
    const formatFileSize = (size) => {
        if (size < 1024 * 1024) {
            return `${(size / 1024).toFixed(2)} KB`;  // Less than 1 MB, show in KB
        } else {
            return `${(size / (1024 * 1024)).toFixed(2)} MB`;  // Greater than or equal to 1 MB, show in MB
        }
    };

    return (
        <Container maxWidth="md" sx={{ marginTop: '50px' }}>
            <Paper elevation={3} sx={{ padding: '30px', borderRadius: '10px' }}>
                <Typography variant="h4" gutterBottom>
                    Create a New Translation Project
                </Typography>

                {/* Project Name */}
                <Box sx={{ marginBottom: '20px' }}>
                    <TextField
                        label="Project Name"
                        variant="outlined"
                        fullWidth
                        required
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                    />
                </Box>

                {/* Source and Multiple Target Languages Side by Side */}
                <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>Source Language</InputLabel>
                            <Select
                                value={sourceLanguage}
                                onChange={(e) => setSourceLanguage(e.target.value)}
                                required
                            >
                                {languageOptions.map((language) => (
                                    <MenuItem key={language.code} value={language.code}>
                                        <Flag code={language.countryCode} style={{ marginRight: '10px', width: '20px' }} />
                                        {language.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>Target Languages</InputLabel>
                            <Select
                                value={targetLanguages}
                                onChange={(e) => setTargetLanguages(e.target.value)}
                                multiple  // Allow multiple selections
                                required
                            >
                                {languageOptions.map((language) => (
                                    <MenuItem key={language.code} value={language.code}>
                                        <Flag code={language.countryCode} style={{ marginRight: '10px', width: '20px' }} />
                                        {language.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                {/* Deadline (Optional) */}
                <Box sx={{ marginBottom: '20px', paddingTop: '20px' }}>
                    <TextField
                        label="Deadline"
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        value={deadline}
                        onChange={(e) => setDeadline(e.target.value)}
                    />
                </Box>

                {/* Drag-and-Drop File Input */}
                <Box
                    {...getRootProps()}
                    sx={{
                        border: '2px dashed gray',
                        borderRadius: '15px',
                        backgroundColor: isDragActive ? '#f0f0f0' : '#fafafa',
                        padding: '50px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        marginBottom: '20px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        transition: 'background-color 0.2s ease-in-out',
                    }}
                >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <Typography>Drop the file here...</Typography>
                    ) : (
                            <Typography>
                                {file ? `Selected File: ${file.name}` : 'Drag and drop a .doc, .docx, or .idml file here, or click to select'}
                            </Typography>

                    )}
                </Box>

                {/* Display file info, delete icon, and upload progress */}
                {file && (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                        <Box>
                            <Typography variant="body1">File Name: {file.name}</Typography>
                            <Typography variant="body2">File Size: {formatFileSize(file.size)}</Typography> {/* Format file size */}
                            <LinearProgress variant="determinate" value={uploadProgress} />
                        </Box>
                    </Box>
                )}

                {/* Create Project Button */}
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleProjectCreation}
                    fullWidth
                    sx={{ marginTop: '20px', padding: '10px 0', fontSize: '16px' }}
                    disabled={uploadProgress !== 100}
                >
                    Create Projects
                </Button>

                {error && <Typography color="error" sx={{ marginTop: '20px' }}>{error}</Typography>}
                {successMessage && <Typography color="primary" sx={{ marginTop: '20px' }}>{successMessage}</Typography>}
            </Paper>
        </Container>
    );
};

export default NewTranslation;
