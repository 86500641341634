// isoLanguages.js
const isoLanguages = [
    { code: 'en', name: 'English', countryCode: 'GB' },
    { code: 'es', name: 'Spanish', countryCode: 'ES' },
    { code: 'fr', name: 'French', countryCode: 'FR' },
    { code: 'de', name: 'German', countryCode: 'DE' },
    { code: 'zh', name: 'Chinese', countryCode: 'CN' },
    // Add more languages...
];

export default isoLanguages;
